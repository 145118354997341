import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../../Provider/AuthProvider'
import './style.scss'
import { roleEnum, roleEnumString } from '../../../types/user'
import { Validate_Credentials } from '../../../services/credentials'
import { LightTooltip } from '../../tooltips/text'
import { Fade } from '@mui/material'
import { Key } from '@mui/icons-material'
import { Navigate } from 'react-router-dom'
const UserInfo = () => {
  const { user, logout, refreshToken } = useAuth()
  const [credentialValide, setcredentialValide] = useState<boolean>(false)
  const [isVisible, setIsVisible] = useState(true)
  const [baner, setBaner] = useState(false)
  const [timeLeft, setTimeLeft] = useState<number>(0)
  const [leftPosition, setLeftPosition] = useState(0)
  const elementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = document.getElementById('main')
    if (!container) return

    const calculatePosition = () => {
      if (container && elementRef.current) {
        const containerRect = container.getBoundingClientRect()
        const elementWidth = elementRef.current.offsetWidth

        // Calcula la posición horizontal
        const newLeftPosition = containerRect.left + (containerRect.width - elementWidth) / 2
        setLeftPosition(newLeftPosition + 10)
      }
    }

    // Ejecutar al inicio
    calculatePosition()

    // Crear un ResizeObserver para monitorear cambios en el tamaño del contenedor
    const resizeObserver = new ResizeObserver(() => {
      calculatePosition()
    })

    resizeObserver.observe(container)

    // Limpieza
    return () => {
      resizeObserver.disconnect()
    }
  }, [baner])

  useEffect(() => {
    if (!user) return

    const timeToExpere = user.timeToExpere // tiempo de 24 hasta 0 segundos  valor en segundos
    setTimeLeft(timeToExpere)

    // Función para consultar el estado de la otra aplicación
    const container = document.getElementById('main')! // Reemplaza con el ID de tu contenedor de scroll

    const getData = async () => {
      try {
        const validate = await Validate_Credentials()
        setcredentialValide(validate)
      } catch (error) {
        console.error('error al consultar Credenciales')
        setcredentialValide(false)
      }
    }

    const handleScroll = () => {
      if (container.scrollTop > 20) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
      }
    }

    // Añade el evento de scroll
    container.addEventListener('scroll', handleScroll)

    // Llamar a la función al montar el componente
    getData()

    // Limpia el evento cuando el componente se desmonta
    return () => container.removeEventListener('scroll', handleScroll)
  }, [user])

  useEffect(() => {
    if (!timeLeft || timeLeft <= 0) return

    const interval = setInterval(() => {
      setTimeLeft(prevTime => {
        if (!prevTime) return 0

        if (prevTime <= 300 && !baner) {
          // equivale a 5min
          setBaner(true) // Ejecutar acción cuando el tiempo llegue a cero
        }

        if (prevTime <= 1) {
          logout()
          clearInterval(interval)
          setBaner(false) // Detener el contador cuando llegue a cero
          executeAction() // Ejecutar acción cuando el tiempo llegue a cero
        }

        return prevTime - 1
      })
    }, 1000) // El intervalo es de 1000ms (1 segundo)

    // Limpiamos el intervalo al desmontar el componente
    return () => clearInterval(interval)
  }, [timeLeft])

  // Función que se ejecutará cuando el contador llegue a cero
  const executeAction = () => {
    console.log('El contador ha llegado a cero!')
    // Aquí puedes agregar cualquier acción que quieras ejecutar
  }

  // Función para convertir el tiempo restante en formato MM:SS
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  }

  if (!user) return <Navigate to="/login" />

  return (
    <>
      {baner && (
        <div
          className="expirationWarning borderAnimateIpass"
          ref={elementRef}
          style={{
            left: `${leftPosition}px`
          }}
        >
          <div className="inner">
            <div className="textZone">
              <h4>tu sesión se cerrara en: </h4>Al concluir el tiempo, deberás inciar sesión
              nuevamente.
            </div>
            <div className="time">{formatTime(timeLeft)}</div>
            <div className="resumeContainer">
              <div className=" button borderAnimateIpass" onClick={refreshToken}>
                <div className="inner">RENOVAR</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`${isVisible ? 'containerUserInfo visible' : ' containerUserInfo hidden'}`}
      >
        <div className="imageContainer">
          <img
            src={user.photo !== 'undefined' ? user.photo : `images/icons/user.png`}
            alt=""
          />
        </div>
        <div className="textContainer">
          <p>
            {roleEnumString[user.role as roleEnum].toLocaleUpperCase()}
            {': '}
            {user.name.toLocaleUpperCase()}
          </p>
          {user.client?.name && <p> {user.client.name.toUpperCase()}</p>}
          <LightTooltip
            title={credentialValide ? 'CREDENCIALES VALIDAS' : 'SE REQUIERE CREDENCIALES'}
            followCursor
            TransitionComponent={Fade}
            placement="right"
          >
            <div>
              <Key style={{ color: credentialValide ? 'green' : 'red', fontSize: '1.5rem' }} />
            </div>
          </LightTooltip>
        </div>
      </div>
    </>
  )
}

export default UserInfo
