import { Credentials, CredentialsCreate } from '../types/credentials'
import axiosInstance from './axiosInstance'

export const ListCredentials = async (): Promise<Credentials[]> => {
  try {
    const response = await axiosInstance.get(`/api/credentials/`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de validateToken:', error)
    throw error
  }
}

export const Validate_Credentials = async (): Promise<boolean> => {
  try {
    const response = await axiosInstance.get(`/api/credentials/validate_credentials`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de validateToken:', error)
    throw error
  }
}

export const CreateCredentials = async ({
  userName,
  password
}: CredentialsCreate): Promise<Credentials> => {
  try {
    const response = await axiosInstance.post(`/api/credentials/`, {
      userName,
      password
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de Set_Credentials:', error)
    throw error
  }
}

export const updateCredentials = async ({
  userName,
  password,
  id
}: Credentials): Promise<Credentials> => {
  try {
    const response = await axiosInstance.put(`/api/credentials/${id}`, {
      userName,
      password
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de Set_Credentials:', error)
    throw error
  }
}

export const DeleteCredentials = async (id: string): Promise<boolean> => {
  try {
    const response = await axiosInstance.delete(`/api/credentials/${id}`)

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error en la solicitud de DeleteCredentials:', error)
    throw error
  }
}
