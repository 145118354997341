import React, { useEffect, useState } from 'react'
import './style.scss'
import { IconButton } from '@mui/material'
import { Add, Visibility } from '@mui/icons-material'
import Modal from '../../components/GeneralComponents/Modal'
import { ConfigProvider, Input } from 'antd'

import {
  CreateCredentials,
  DeleteCredentials,
  ListCredentials,
  updateCredentials
} from '../../services/credentials'
import { useLoading } from '../../Provider/LoadingProvider'
import DeleteButton from '../../components/Table/components/DeleteButton'
import { Credentials } from '../../types/credentials'
import { useAuth } from '../../Provider/AuthProvider'

const CredentialModal: React.FC<{
  selected?: Credentials
  password: string
  setPassword: (value: string) => void
  userName: string
  setUserName: (value: string) => void
  handleSave: () => void
}> = ({ selected, password, setPassword, userName, setUserName, handleSave }) => {
  return (
    <div className="CredentialModal">
      <div className="borderAnimateIpass border">
        <div className="inner labelCredentials">
          <p>Usuario</p>
          <ConfigProvider
            theme={{
              components: {
                Input: { colorTextPlaceholder: '#fffbe6' }
              }
            }}
          >
            <Input.Password
              className="input"
              variant="borderless"
              value={userName}
              onChange={e => setUserName(e.target.value)}
              placeholder={selected ? selected.userName : ''}
            />
          </ConfigProvider>
        </div>
      </div>

      <div className="borderAnimateIpass border">
        <div className="inner labelCredentials">
          <p>Clave</p>
          <ConfigProvider
            theme={{
              components: {
                Input: { colorTextPlaceholder: '#fffbe6' }
              }
            }}
          >
            <Input.Password
              className="input"
              variant="borderless"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder={selected ? selected.password : ''}
            />
          </ConfigProvider>
        </div>
      </div>

      <div
        className={`button ${
          userName && password ? 'borderAnimateIpass' : 'borderAnimateIpassDisable disable'
        }`}
        onClick={userName && password ? handleSave : undefined}
      >
        <div className="inner">{selected ? 'ACTUALIZAR' : 'GUARDAR'}</div>
      </div>
    </div>
  )
}

const CredentialsPage = () => {
  const { initial } = useAuth()

  const { startLoading, stopLoading } = useLoading()
  const [open, setOpen] = useState<boolean>(false)
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [selected, setSelected] = useState<Credentials | undefined>(undefined)
  const [data, setData] = useState<Credentials[]>([])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      startLoading()
      const receptionist = await ListCredentials()
      setData(receptionist)
    } catch (error) {
      console.error(error)
      setData([])
    } finally {
      stopLoading()
    }
  }

  const handleSave = async () => {
    try {
      startLoading()
      if (selected) {
        await updateCredentials({ id: selected.id, userName, password })
      } else {
        await CreateCredentials({ userName, password })
      }
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
      onCloseModal()
    }
  }

  const handleDelete = async (id: string) => {
    try {
      startLoading()
      await DeleteCredentials(id)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
      onCloseModal()
    }
  }

  const handleView = (value: Credentials) => {
    setSelected(value)
    setOpen(true)
  }

  const Actions = ({ item }: { item: Credentials }) => {
    return (
      <>
        <IconButton onClick={() => handleView(item)}>
          <Visibility style={{ color: 'white' }} />
        </IconButton>
        <DeleteButton OnDelete={() => handleDelete(item.id)} />
      </>
    )
  }

  const onCloseModal = async () => {
    setOpen(false)
    setSelected(undefined)
    setPassword('')
    setUserName('')
    await initial()
    await getData()
  }

  return (
    <div className="CredentialsContainer">
      <div className="title">
        <p>CREDENCIALES REGISTRO</p>
        <IconButton
          onClick={() => setOpen(true)}
          disabled={data.length > 0 ? true : false}
          style={{ padding: '0px' }}
        >
          <Add style={{ color: `${data.length > 0 ? 'gray' : 'white'}` }} />
        </IconButton>
      </div>
      <div className="ListContainer">
        <div className="data">
          <p>Usuario: </p>
          <p>{data.length > 0 ? data[0].userName : '***********'}</p>
        </div>

        <div className="data">
          <p>Clave: </p>
          <p>{data.length > 0 ? data[0].password : '***********'}</p>
        </div>

        <div className="data">{data.length > 0 && <Actions item={data[0]} />}</div>
      </div>

      <Modal
        isOpen={open}
        onClose={onCloseModal}
        borderColor
        styleContainerContent={{ padding: '5px', margin: '0px' }}
        styleContainer={{
          width: '520px',
          height: '260px',
          padding: '0px',
          overflow: 'hidden',
          margin: '5px',
          borderRadius: '20px'
        }}
        styleClose={{ top: '8px', right: '8px' }}
      >
        <CredentialModal
          selected={selected}
          password={password}
          setPassword={setPassword}
          userName={userName}
          setUserName={setUserName}
          handleSave={handleSave}
        />
      </Modal>
    </div>
  )
}

export default CredentialsPage
